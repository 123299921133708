import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

export interface IErrorMessageProps {
  error: string;
  wrapperBackgroundColor: string;
  wrapperMargin: string;
  messagePadding: string;
  messageTextAlign: string;
  errorTextColor: string;
}

export const ErrorMessage = (props: IErrorMessageProps) => {
  const { t } = useTranslation();

  /*
    Passing styling props directly to the component to
    avoid using a custom webpack config alongside the application's CRA.
  */

  const wrapperStyling = {
    backgroundColor: props.wrapperBackgroundColor,
    margin: props.wrapperMargin
  };

  const messageStyling = {
    padding: props.messagePadding,
    textAlign: props.messageTextAlign,
    color: props.errorTextColor
  } as CSSProperties;

  return (
    <div className="error-wrapper" style={wrapperStyling}>
      <div className="error-text" style={messageStyling}>
        {t(props.error)}
      </div>
    </div>
  );
};
