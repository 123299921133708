import React from 'react';

/**
 * The data required to configure the navigation header
 */
export interface INavigationHeaderProps {

   /**
    * The text to be displayed
    */
    displayText: string;
    
   /**
    * The background colour of the header bar
    */
    backgroundColour: string;
   
   /**
    * The text colour
    */
    textColour: string;

   /**
    * The font family for the display text
    */
    fontFamily: string;

   /**
    * The font size for the display text
    */
    fontSize: string;

   /**
    * The font weight for the display text, e.g. 400/600/etc
    */
    fontWeight: number;

   /**
    * The padding for the navigation header, e.g. '9px 11px'
    */
    padding: string;

   /**
    * The function fired when the back icon is clicked
    */
   backIconClickHandler: () => void;

   /**
    * The name of the icon including the sprite, e.g. `{iconSprite}#{iconName}`
    */
   backIconName: string;

   /**
    * The display width of the back icon in pixels
    */
    iconWidth: string;

    /**
     * The view box for the svg
     */
    iconViewBox: string;

    /**
     * Left padding for the navigation text
     */
    navTextPadding: string;
}

export const NavigationHeader = (props: INavigationHeaderProps) => {
    return (
        <div id='header-container' onClick={props.backIconClickHandler} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: props.backgroundColour, color: props.textColour, padding: props.padding }}>
                <svg id='back-icon' viewBox={props.iconViewBox} style={{width: props.iconWidth}}>
                    <use href={props.backIconName} />
                </svg>
            <div id='display-text' style={{padding: props.navTextPadding, fontFamily: props.fontFamily, fontSize: props.fontSize, fontWeight: props.fontWeight}}>{props.displayText}</div>
        </div>
    );
};