import React, { CSSProperties }  from 'react';

import Strip from '../Strip';
import { SportCode } from '@incentivegames/ig-types/lib/enums/sport/sportCode';

interface IContainerStyle {
  diameter?: number;
  boxSizing: string;
  display: string;
  alignItems: string;
  justifyContent: string;
  background: string;
  borderRadius: string;
  border: string;
}

interface IStripStyle {
  marginTop: string;
  width: string;
  height: string;
}

export interface IStripContainerStyle {
  containerStyle:IContainerStyle;
  stripStyle:IStripStyle
  popupBorder?: string;  //this is the styling for the props.variant
}

export interface IStripContainerProps {
  participantCode?: string;
  variant?: string;
  sportCode?: SportCode;
  stripContainerStyle: IStripContainerStyle
}

export const StripContainer = (props: IStripContainerProps) => {
  const variant: string = props.variant ? `strip-container--${props.variant}` : '';

  const constainerStyling = {
    width: `${props.stripContainerStyle.containerStyle.diameter || 42}px`, 
    height: `${props.stripContainerStyle.containerStyle.diameter || 42}px`, 
    boxSizing: props.stripContainerStyle.containerStyle.boxSizing,
    display: props.stripContainerStyle.containerStyle.display,
    alignItems: props.stripContainerStyle.containerStyle.alignItems,
    justifyContent: props.stripContainerStyle.containerStyle.justifyContent,
    background: props.stripContainerStyle.containerStyle.background,
    borderRadius: props.stripContainerStyle.containerStyle.borderRadius,
    border: props.stripContainerStyle.containerStyle.border
  } as CSSProperties;

  const stripStyling = { 
    marginTop: props.stripContainerStyle.stripStyle.marginTop,
    width: props.stripContainerStyle.stripStyle.width,
    height: props.stripContainerStyle.stripStyle.height
  }

  return (
    <div
      className={`strip-container ${variant}`} style={constainerStyling}>
      <Strip teamCode={props.participantCode} sportCode={props.sportCode} className="strip-container__strip" style={stripStyling}/>
    </div>
  );
};


