import React from 'react';

export interface IErrorPopupProps {
  alertIconName: string;
  alertIconViewBox: string;
  closeIconName: string;
  closeIconViewBox: string;
  displayText: string;
  displayFont: string;
  errorPopupBackgroundColor: string;
  errorPopupTextColor: string;
  errorPopupAlertIconBackgroundColor: string;
  closeIconClickHandler: () => void;
  textTransform?: string;
}

export const ErrorPopup = (props: IErrorPopupProps) => {

  /** 
   * This is required due to a limitation on Typescript with CSS Styles 
   * whereby we can't assign a string variable to the textTransform style property 
   */
  const mapTextTransform = () => {
    switch (props.textTransform){
      case 'uppercase':
        return 'uppercase';
      case 'capitalize':
        return 'capitalize';
      case 'lowercase':
        return 'lowercase'
      case 'none':
      default: 
        return 'none';
    }
  }

  return (
    <div id="error-popup-container" style={{
        display: "flex", 
        width: "100%", 
        height: "32px", 
        justifyContent: "space-between", 
        alignItems: "center", 
        backgroundColor: props.errorPopupBackgroundColor, 
        color: props.errorPopupTextColor,
        borderRadius: "4px",
        boxShadow: "0px 2px 4px rgba(0,0,0,0.65)"
      }}>
      <div id="alert-icon" style={{
        width: "36px",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: props.errorPopupAlertIconBackgroundColor,
        borderRadius: "4px 0 0 4px"
      }}>
        <svg viewBox={props.alertIconViewBox}>
          <use href={props.alertIconName} ></use>
        </svg>          
      </div>
      <div id="error-popup-text" style={{
        fontSize: "12px",
        fontWeight: 300,
        textAlign: "center",
        fontFamily: props.displayFont,
        textTransform: mapTextTransform()
      }}>{props.displayText}</div>
      <div id="close-icon" onClick={() => props.closeIconClickHandler()} style={{
        width: "36px",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "4px 0 0 4px"
      }}>
        <svg viewBox={props.closeIconViewBox}>
          <use href={props.closeIconName} ></use>
        </svg>          
      </div>
    </div>
  );
};
