import React from 'react';
import { ErrorMessage } from '../ErrorMessage/index';

export interface IErrorBoundaryProps {
  onError: (error: string, info: string) => void;
  wrapperBackgroundColor: string;
  wrapperMargin: string;
  messagePadding: string;
  messageTextAlign: string;
  errorTextColor: string;
  customErrorMessage: string;
}

interface IErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true });
    this.props.onError(error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <ErrorMessage
            error={this.props.customErrorMessage ? this.props.customErrorMessage : 'generic-error'}
            wrapperBackgroundColor={this.props.wrapperBackgroundColor}
            wrapperMargin={this.props.wrapperMargin}
            messagePadding={this.props.messagePadding}
            messageTextAlign={this.props.messageTextAlign}
            errorTextColor={this.props.errorTextColor}
          />
        </div>
      );
    }
    return this.props.children;
  }
}
