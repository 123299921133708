import React from 'react';
import { SportCode } from '@incentivegames/ig-types/lib/enums/sport/sportCode';

interface IStripStyle {
  marginTop: string;
  width: string;
  height: string;
}

interface IStripProps {
  teamCode: string;
  sportCode: SportCode;
  className?: string;
  style: IStripStyle;
}

const Strip = ({ teamCode, sportCode, className, style }: IStripProps) => {
  if (!teamCode) {
    return null;
  }

  return (
    <img
      className={className}
      style={style}
      src={`strips/${teamCode}.svg`}
      alt={teamCode}
      onError={(e) => (e.currentTarget.src = `strips/${sportCode}.svg`)}
    />
  );
};

export default Strip;
